import React from "react";
import { Button } from "../components/Button";
import { PageLayout } from "../components/PageLayout";
import { SectionContainer } from "../components/sections/SectionContainer";
import Image from "next/legacy/image";
import Link from "next/link";
export default function Custom404() {
  return <PageLayout className="relative overflow-hidden bg-highlight" data-sentry-element="PageLayout" data-sentry-component="Custom404" data-sentry-source-file="404.tsx">
      <SectionContainer className="z-10 mt-10 sm:mt-40" data-sentry-element="SectionContainer" data-sentry-source-file="404.tsx">
        <h1 className="text-e3 sm:text-e2">
          404 - We can’t seem to find that page
        </h1>
        <p className="text-body-small sm:text-body-large mt-6 max-w-2xl">
          Click below and let’s get you back on track.
        </p>
        <Link href="/" passHref data-sentry-element="Link" data-sentry-source-file="404.tsx">
          <Button data-testid={"button-404-not-found-page"} variant="dark" title="Back to Homepage" className="mt-10" data-sentry-element="Button" data-sentry-source-file="404.tsx" />
        </Link>
      </SectionContainer>
      <div className="absolute top-1/4 -right-1/3 z-0 h-full min-h-screen w-full md:top-0 md:-right-1/3">
        <Image src="/images/birds.svg" alt="birds" layout="fill" data-sentry-element="Image" data-sentry-source-file="404.tsx" />
      </div>
    </PageLayout>;
}