import React, { ReactNode } from "react";
import clsx from "clsx";
interface Props {
  className?: string;
  innerClassName?: string;
  variant?: "wide" | "narrow" | "tall" | "short";
  sibling?: ReactNode;
  id?: string;
  children: ReactNode;
}
export type SectionVariant = Props["variant"];
export const SectionContainer: React.FC<Props> = ({
  variant = "wide",
  className,
  innerClassName,
  children,
  sibling,
  id
}) => {
  return <div id={id} className={clsx(className, "snap-mt-12 relative")} data-sentry-component="SectionContainer" data-sentry-source-file="SectionContainer.tsx">
      <div className={clsx("relative mx-auto", {
      "max-w-7xl py-16 px-8 md:py-20 lg:px-10": variant === "wide",
      "max-w-7xl py-0  px-8 lg:px-10": variant === "short",
      "max-w-7xl py-16 px-8  md:py-36 lg:px-10 ": variant === "tall",
      "max-w-2xl items-center py-16 px-8 md:py-20 lg:px-0": variant === "narrow"
    }, innerClassName)}>
        {children}
      </div>
      {sibling}
    </div>;
};